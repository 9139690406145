<template>
  <FVLmtV2 :match-id="this.$route.params.id"/>
</template>
<script>
import FVLmtV2 from "./FVLmtV2";

export default {
  name: "FVLmtV2Container",
  components: {FVLmtV2},
}
</script>
<style scoped>

</style>
